import request from './request'
import { QueryRangeDto, QueryMonthDto } from './dto'
// 查询余额
interface GetBalanceResDto{
    eleSignOverAmount: number
    payBalance: number
    wesureBalance: number,
    canUseBalance:number
}
export function getBalance (): Promise<GetBalanceResDto> {
    return request.get('/rest/home/statistics/balance')
}

// 查询投保趋势
interface GetInsuranceTrendResDto{
    totalWesureCount: number
    list: {
        wesureCount: number
        date: string
    }[],
    isOpenWesure: boolean
}
export function getInsuranceTrend (data: any): Promise<GetInsuranceTrendResDto> {
    return request.get('/rest/home/statistics/analyse-wesure-count', {
        params: data
    })
}

// 查询电子签趋势
interface GetContractTrendResDto{
    list: {
        signedCount: number
        date: string
    }[],
    isOpenEleSign: boolean
}
export function getContractTrend (data: QueryRangeDto): Promise<GetContractTrendResDto> {
    return request.get('/rest/home/statistics/analyse-ele-sign-count', {
        params: data
    })
}

// 查询发薪趋势
interface GetPayTrendResDto{
    payTotalMoney: number // 实发金额
    payReceiveTotalMoney: number // 领薪金额
    payOrderCount: number // 发薪项目数
    payStopTotalMoney: number // 停止金额
    payAvailableTotalMoney: number // 待领金额
    list: {
        date: string
        payTotalMoney: number
        totalMoney: number
        payReceiveTotalMoney: number
    }[]
    isOpenPaySalary: boolean
}
export function getPayTrend (data: QueryMonthDto): Promise<GetPayTrendResDto> {
    return request.get('/rest/home/statistics/analyse-pay', {
        params: data
    })
}

// 查询未领薪人数和简历库人数
interface GetPeopleCountResDto{
    addressPeopleCount: number
    unclaimedCount: number
    advanceUnclaimedCount: number
}
export function getPeopleCount (): Promise<GetPeopleCountResDto> {
    return request.get('/rest/home/statistics/people-counting')
}

// 日日保 仪表盘图表/rest/home/statistics/get-daliy-insur-risk
export function getInsuranceRisk (data: {coName:string}): Promise<any> {
    return request.get('/rest/home/statistics/get-daliy-insur-risk', {
        params: data
    })
}

// 获取企业名称
export function getEnterpriseList (data: {name:string}): Promise<any> {
    return request.get('/rest/company/searchEnterpriseInfo', {
        params: data
    })
}

// 获取团队成员
export function getTeamList (): Promise<any> {
    // /rest/company/get-member-list
    return request.get('/saas/note/saasCompanyEmployee')
}

// 添加团队成员
export function addBindCompanySupplier (data: any): Promise<any> {
    return request.post('/rest/pay/serve-company/company-supplier', data)
}

// 微信二维码充值 recharge
export function recharge (data: any): Promise<any> {
    return request.post('/rest/company/transactions', data)
}
